<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('customer.基本信息') }}</div>
      <van-collapse-item :title="$t('customer.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('customer.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户编码') }}</h5>
          <p>{{ ruleForm.customerCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户分类') }}</h5>
          <p>{{ ruleForm.customerType | setDict('CUSTOMER_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.性质') }}</h5>
          <p>{{ ruleForm.customerPositioning | setDict('CUSTOMER_NATURE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户简称') }}</h5>
          <p>{{ ruleForm.customerNewName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.客户全称') }}</h5>
          <p>{{ ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.所在地区') }}</h5>
          <p>{{ ruleForm.tel }} {{ ruleForm.prov }} {{ ruleForm.city }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.详细地址') }}</h5>
          <p>{{ ruleForm.address }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.经营地址') }}</h5>
          <p>{{ ruleForm.businessAddr }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.企业分类') }}</h5>
          <p>{{ ruleForm.organizationType | setDict('CUSTOMER_COMPANY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.公司性质') }}</h5>
          <p>{{ ruleForm.natureEnterprise | setDict('ENTERPRISE_NATURE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.注册资本') }}(万元)</h5>
          <p>{{ ruleForm.registeredCapital }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.成立时间') }}</h5>
          <p>{{ ruleForm.establishmentDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.纳税人唯一识别码') }}</h5>
          <p>{{ ruleForm.taxpayerCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.币别') }}</h5>
          <p>{{ ruleForm.registeredCurrency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('customer.合作产品') }}</h5>
          <p>{{ ruleForm.mainBusinessProducts }}</p>
        </div>
      </van-collapse-item>
      
      <van-collapse-item :title="$t('customer.联系人')" name="7">
        <div v-if="!ruleForm.customerContactList || !ruleForm.customerContactList.length">{{ $t('customer.无') }}</div>
        <div v-for="(item, index) in ruleForm.customerContactList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('customer.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('customer.联系人') }}</h5>
            <p>{{ item.contact }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('customer.电话') }}</h5>
            <p>{{ item.telephone }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('customer.传真') }}</h5>
            <p>{{ item.fax }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('customer.邮箱') }}</h5>
            <p>{{ item.email }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('customer.客户信用信息') }}</div>
      <div v-for="(customerOrgCredit, index) in ruleForm.customerOrgCreditList" :key="index">
        <div v-if="customerOrgCredit.orgId === activeOrgId">
          <van-collapse-item :title="$t('customer.授信申请')" name="2">
            <div class="items">
              <h5>{{ $t('customer.建议授信额度') }}(万元)</h5>
              <p>{{ customerOrgCredit.proposalCreditLimit | notationValue(4) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信类型') }}</h5>
              <p>{{ customerOrgCredit.creditType | setDict('CUSTOMER_LC_TYPE') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信期限') }}</h5>
              <p>{{ customerOrgCredit.creditDate }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信币别') }}</h5>
              <p>{{ customerOrgCredit.proposalCreditLimitCurrency | setDict('CURRENCY_TYPE') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.信用账期') }}(天)</h5>
              <p>{{ customerOrgCredit.proposalCreditArrearsPeriod ? customerOrgCredit.proposalCreditArrearsPeriod:0 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.预计毛利率') }}(%)</h5>
              <p>{{ customerOrgCredit.expectGrossMargin ? customerOrgCredit.expectGrossMargin+'%':'0%' }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.预计业务量') }}</h5>
              <p>{{ customerOrgCredit.expectBusinessCount ? customerOrgCredit.expectBusinessCount:0 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信申请理由') }}</h5>
              <p>{{ customerOrgCredit.applyLcReasons }}</p>
            </div>
          </van-collapse-item>
          <van-collapse-item :title="$t('customer.授信评估')" name="3">
            <div class="items" v-show="!activeOrgIsHeadOrg">
              <h5>{{ $t('customer.授信金额') }}USD(万元)</h5>
              <p>{{ customerOrgCredit.creditLimitDollar | notationValue(4) }}</p>
            </div>
            <div class="items" v-show="!activeOrgIsHeadOrg">
              <h5>{{ $t('customer.汇率') }}</h5>
              <p>{{ customerOrgCredit.creditLimitDollarRate }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信金额') }}(万元)</h5>
              <p>{{ customerOrgCredit.creditLimit | notationValue(4) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信币别') }}</h5>
              <p>{{ customerOrgCredit.creditLimitCurrency | setDict('CURRENCY_TYPE') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信名单') }}</h5>
              <p>{{ customerOrgCredit.creditNameList | setDict('CUSTOMER_LC_NAME_LIST') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.信用账期') }}(天)</h5>
              <p>{{ customerOrgCredit.proposalArrearsPeriod ? customerOrgCredit.proposalArrearsPeriod:0 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('customer.授信意见') }}</h5>
              <p>{{ customerOrgCredit.creditRemarks }}</p>
            </div>
          </van-collapse-item>
          <van-collapse-item :title="$t('customer.中信保授信申请信息')" name="4">
            <div v-if="!ruleForm.sinosureCreditApplicationList || !ruleForm.sinosureCreditApplicationList.filter(item => item.orgId === activeOrgId).length">{{ $t('customer.无') }}</div>
            <div v-for="(item, index) in ruleForm.sinosureCreditApplicationList.filter(item => item.orgId === activeOrgId)" :key="index" class="detailLine">
              <div class="items">
                <h5>{{ $t('customer.序号') }}</h5>
                <p>{{ index + 1 }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('customer.申请日期') }}:</h5>
                <p>{{ item.applyDate }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('customer.批复额度') }}:</h5>
                <p>{{ item.replyAmount | formatAmount }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('customer.有效期') }}:</h5>
                <p>{{ item.validityDate }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('customer.登记人') }}:</h5>
                <p>{{ item.registrantMan }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('customer.登记日期') }}:</h5>
                <p>{{ item.registrationDate }}</p>
              </div>
              <van-divider dashed :style="{ borderColor: '#333' }"/>
            </div>
          </van-collapse-item>
        </div>
      </div>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'));
  const getOrgInfo = () => JSON.parse(getUserInfo().org);

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        activeOrgId: this.targetOrgId || getUserInfo().companyId,
        targetOrgId: '',
        loading: false,
        finished: false,
        activeNames: '1',
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
        ruleForm: {
          id: '',
          customerType: '',
          customerCode: '',
          customerName: '',
          isRename: '',
          telephone: '',
          contact: '',
          email: '',
          address: '',
          operationType: '',
          internalRelatedParties: 'Y',
          isInvalid: 'N',
          isSupplier: 'N',
          invalidDate: '',
          tel: '中国',
          prov: '省份',
          city: '城市',
          editStatus: 'PREPARING',
          purArea: '',
          purAreaCity: '',
          processInstanceId: '',
          supId: '',
          isTaxpayer: 'N',
          taxpayerIdentificationNumber: '',
          customerBillingAddress: '',
          customerBillingPhone: '',
          customerBankOfDeposit: '',
          customerBillingAccount: '',
          suggestedSalesQuota: '',
          creditProposal: '',
          salesCreditLine: '',
          sellOnCreditLimit: '',
          mortgageType: '',
          mortgageAmount: '',
          mortgageDate: '',
          isOverdue: 'N',
          orderStatus: '',
          industry: '',
          industryDetail: '',
          customerUsName: ''
        },
        woCode: 'customerHeader',
        processInstanceName: '客户信息'
      };
    },
    computed: {
      // 当前活动的页签账套是总部账套
      activeOrgIsHeadOrg() {
        return this.activeOrgId === '517730389255520257';
      },
    },
    props: {},
    methods: {
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      // 处理通过流程审批进入的请求
      handleInitApprovalData({ businessKey, id, taskType }) {
        this.taskInstanceId = id;
        this.taskType = taskType;
        // 通过授信信息查找客户主体
        const url = '/microarch/customer/customerHeader/customerOrgCredit';
        this.$myHttp.get(url, { params: { id: businessKey } }).then(json => {
          this.targetOrgId = json.data.entity.orgId;
          this.ruleForm.id = json.data.entity.headerId;
          this.backfilleditData();
        });
      },
      backfilleditData(id) {
        let _this = this;
        this.$myHttp({
          method: 'POST',
          url: '/microarch/customer/customerHeader/view2',
          data: {
            entity: {
              id: _this.ruleForm.id,
              currentOrgId: getUserInfo().companyId
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.$refs.historyPanel.getData(this.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('customer.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.customerCode, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/customer/customerHeader/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('customer.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.customerCode, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/customer/customerHeader/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/customer/customerHeader/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('customer.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/customer/customerHeader/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {
      let selectRow = JSON.parse(this.$route.query.row);
      this.processInstanceId = selectRow.pid;
    },
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
          this.handleInitApprovalData(selectRow);
        }
      }
      // if (this.ruleForm.id) {
      //   this.backfilleditData(this.ruleForm.id);
      // }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      },
      formatAmount(v, decimal = 2, isCovering = true) {
        return util.formatAmount(v, decimal, isCovering);
      },
      notationValue(v, exponent) {
        return v / Math.pow(10, exponent) || 0;
      },
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
